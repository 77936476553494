@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
:root {
  --primary-dark: #5651e5;
  --primary-light: #709dff;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Nunito", serif;
}
@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply font-[Crimson];
  }
  li {
    @apply text-sm;
  }
  button {
    @apply px-4 py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white;
  }
  h2 {
    @apply text-xl font-bold;
  }
}
